@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&family=Homemade+Apple&display=swap");
body {
  border: 0;
  margin: 0;
  padding: 0;
}

#app {
  min-width: 640px;
  max-width: 720px;
  margin: 1.25em auto;
}

.tardySlip {
  border: 4px double #222244;
  background: #fcc7d3;
  padding: 1.25em;
  font-family: "Arimo", sans-serif;
  color: #222244;
  text-transform: uppercase;
}
.tardySlip h1, .tardySlip h2 {
  align-self: center;
  text-align: center;
}
.tardySlip h1 {
  font-weight: 700;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0.2em;
  padding-bottom: 0.2em;
}
.tardySlip h2 {
  font-weight: 700;
  padding-top: 0.4em;
  margin-top: 1.05em;
  border-top: 3px solid #222244;
}
.tardySlip .checkbox__input {
  display: grid;
  grid-template-areas: "checkbox";
}
.tardySlip .checkbox__input input {
  opacity: 0;
  width: 1em;
  height: 1em;
}
.tardySlip .checkbox__input input:checked + .checkbox__control svg {
  transform: scale(1);
}
.tardySlip .checkbox__input input:focus + .checkbox__control {
  box-shadow: 0 0 0 0.05em #fcc7d3, 0 0 0.15em 0.1em #222244;
}
.tardySlip .checkbox__input .checkbox__control {
  display: inline-grid;
  width: 1em;
  height: 1em;
  border-radius: 0;
  border: 2px solid #222244;
  margin: 0 !important;
}
.tardySlip .checkbox__input .checkbox__control svg {
  transition: transform 0.1s ease-in 25ms;
  transform: scale(0);
  transform-origin: center;
}
.tardySlip .checkbox__input > * {
  grid-area: checkbox;
}
.tardySlip .row {
  display: flex;
  justify-content: flex-start;
}
.tardySlip .row.date-time {
  margin-bottom: -0.3em;
}
.tardySlip .row.date-time textarea {
  z-index: 100;
}
.tardySlip .row.reason-label {
  margin-top: 1em;
  margin-bottom: -0.5em;
}
.tardySlip .row.reason textarea, .tardySlip .row.signature textarea {
  background-image: linear-gradient(to right, #fcc7d3 7px, transparent 0px), linear-gradient(to left, #fcc7d3 7px, transparent 0px), repeating-linear-gradient(#fcc7d3, #fcc7d3 46px, #222244 46px, #222244 47px, #fcc7d3 47px);
  line-height: 46px;
}
.tardySlip .row.reason textarea {
  padding-top: 0.63em;
  height: 4em;
}
.tardySlip .row.excused span, .tardySlip .row.unexcused span {
  margin: 0.2em;
}
.tardySlip .row.unexcused {
  margin-bottom: -0.65em;
}
.tardySlip .row.signature {
  margin-bottom: -0.5em;
}
.tardySlip .row.signature textarea {
  text-align: right;
  padding: 0.4em 0.5em 0 0;
  font-size: 32px;
  margin-bottom: -0.4em;
  height: 1.8em;
  flex-grow: 4;
}
.tardySlip .row.signature-label {
  font-size: 12px;
}
.tardySlip .row.signature-label .label {
  font-weight: 700;
  padding-right: 0.4em;
}
.tardySlip .row span {
  z-index: 10;
  align-self: flex-end;
  margin: 0 0.2em 0.9em;
}
.tardySlip .row span.date {
  flex-grow: 7;
}
.tardySlip .row span.time {
  flex-grow: 3;
}
.tardySlip .row span.reason {
  margin-bottom: 0;
}
.tardySlip .row span.spacer {
  flex-grow: 9;
}
.tardySlip .row textarea {
  height: 2.1em;
  flex-grow: 9;
  font-family: "Homemade Apple", cursive;
  font-size: 24px;
  text-indent: 6px;
  border: none;
  background-attachment: local;
  background-image: linear-gradient(to right, #fcc7d3 7px, transparent 0px), linear-gradient(to left, #fcc7d3 7px, transparent 0px), repeating-linear-gradient(#fcc7d3, #fcc7d3 36px, #222244 36px, #222244 37px, #fcc7d3 37px);
  padding: 0.05em 0 0 0.45em;
  overflow: hidden;
  resize: none;
}
.tardySlip .row textarea:focus {
  outline: none;
}
.tardySlip .row .footer {
  padding-top: 0.5em;
  font-size: 0.5em;
  vertical-align: baseline;
}

.actions {
  margin-top: 1.25em;
  text-align: center;
}
.actions button {
  margin-left: 1.25em;
}
.actions button:first-child {
  margin-left: 0;
}

footer {
  text-align: center;
  font-family: "Arimo", sans-serif;
  font-size: 12px;
  margin-top: 1.25em;
}
footer a {
  color: #222244;
}